import React, { lazy, Suspense, useEffect } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Loader from "@components/Loader";
import Footer from "@components/Footer";
import { ToastContainer } from "react-toastify";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./utils/ProtectedRoute";
import VehicleCard from "@components/VehicleCard";

const LoginPage = lazy(() => import("@pages/CustomerGatway/LoginPage"));
const LandingPage = lazy(() => import("@pages/LandingPage"));
const RangeRover = lazy(() => import("@pages/RangeRover"));
const VehicleDetails = lazy(() => import("@pages/VehicleDetails"));
const Login = lazy(() => import("@pages/Login"));
const RegisterForm = lazy(() => import("@pages/RegisterForm"));
const VehicleList = lazy(() => import("@pages/VehicleList"));
const Dashboard = lazy(() => import("@pages/Dashboard"));
const Quota = lazy(() => import("@pages/Quota"));
const TestDrive = lazy(() => import("@pages/TestDrive"));
const FinanceAssistant = lazy(() => import("@pages/FinanceAssistant"));
const NewVehicleDetails = lazy(() => import("@pages/NewVehicleDetails"));
const BuyNowPopup = lazy(() => import("@components/BuyNowPopup/index"));
const TermsAndServices = lazy(() => import("@pages/TermsAndServices"));
const NotFound = lazy(() => import("@pages/NotFound.js"));
const ContactUs = lazy(() => import("@pages/ContactUs"));
const CustomerGateway = lazy(() => import("@pages/CustomerGatway"));
const Registration = lazy(() => import("@pages/CustomerGatway/Registration"));
const ResetPassword = lazy(() =>
  import("@pages/CustomerGatway/ForgotPassword")
);
const ForgotPassword = lazy(() =>
  import("@pages/CustomerGatway/ResetPassword")
);
const PreApprovalLoan = lazy(() =>
  import("@pages/PreApprovalLoan/PreApprovalLoan")
);
const Checkout = lazy(() => import("@pages/Checkout"));

const THEME = createTheme({
  typography: {
    fontFamily: "LandRoverOt4-medium",
    fontSize: 20,
    lineHeight: 1.5,
    letterSpacing: 0.32,
    useNextVariants: true,
    suppressDeprecationWarnings: true,
    h6: {
      fontWeight: "bolder",
      fontSize: 20,
    },
    h5: {
      fontWeight: "bolder",
      fontSize: 20,
    },
  },
});
const AllRoutes = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/range-rover" element={<RangeRover />} />
        <Route path="/defender" element={<RangeRover />} />
        <Route path="/discovery" element={<RangeRover />} />
        <Route
          path="/new-vehicle-details/:id/:nameplate_id"
          element={<VehicleDetails />}
        />
        <Route path="/vehicle-list" element={<VehicleList />} />
        <Route path="/register" element={<RegisterForm />} />
        <Route path="/send-me-a-quote/:id" element={<Quota />} />
        <Route path="/book-a-test-drive/:id" element={<TestDrive />} />
        <Route path="/book-a-test-drive" element={<TestDrive />} />
        <Route path="/finance-assistant/:id" element={<FinanceAssistant />} />
        <Route path="/finance-assistant" element={<FinanceAssistant />} />
        <Route path="/contact-us/:id" element={<ContactUs />} />
        <Route path="/terms-and-conditions" element={<TermsAndServices />} />
        <Route
          path="/vehicle-details/:id/:nameplate_id"
          element={<NewVehicleDetails />}
        />
        <Route path="/customerGatway" element={<CustomerGateway />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/login" element={<LoginPage />} />

        <Route path="/forgot-password" element={<ResetPassword />} />
        <Route path="/reset-password/:id" element={<ForgotPassword />} />
        <Route
          path="/checkout"
          element={
            <ProtectedRoute>
              <Checkout />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/pre-approved-loan/:id"
          element={
            <ProtectedRoute>
              <PreApprovalLoan />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
        <Route path="/vehicle-card" element={<VehicleCard />} />
      </Routes>
      <ToastContainer />
      <Footer />
    </>
  );
};

function App() {
  return (
    <>
      <BrowserRouter>
        <MuiThemeProvider theme={THEME}>
          <Suspense
            fallback={
              <div>
                <Loader isLoading={true} />
              </div>
            }
          >
            <AllRoutes />
          </Suspense>
        </MuiThemeProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
