const dev = {
  // BASE_URL: "https://findnewlandrover.vnnovateserver.com/api/",
  BASE_URL: "https://portalnotification.jlrsaportal.co.za/api/",
  // BASE_URL: "https://udaanapi.jlrsaportal.co.za/",
};

const ENV = dev;

export default ENV;

export const GOOGLE_MAP_API_KEY = "AIzaSyA8T5AInUbKgQwKZQMB3JuyfT56CfkhH0A";

export const RECAPTCHA_SITE_KEY = "6LegiqUoAAAAAOL0_bfHmSXGaX1GYAVBEcPVdyD1";
export const RECAPTCHA_SECRET_KEY = "6LegiqUoAAAAAHmYKGs81Nn4S1Tbow8daVyunji2";
